import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Carousel,
  Form,
} from "react-bootstrap";
import FooterComponent from "../components/footer.component";
import Banner from "../img/Jones/banner.jpg";
import NewImagebanner from "../img/Jones/nftislands.png";
import NewImagebannertwo from "../img/Jones/instant.png";
import NewImagebannerthree from "../img/Jones/webfi.png";

import BannerSection from "../img/Jones/banner_2.jpg";
import JayJones from "../img/Jones/HomeImage.jpg";
import Icon1 from "../img/Jones/icons/icon.svg";
import Icon2 from "../img/Jones/icons/icon_1.svg";
import Icon3 from "../img/Jones/icons/icon_2.svg";
import Icon4 from "../img/Jones/icons/icon_3.svg";
import Icon5 from "../img/Jones/icons/icon_4.svg";
import Icon6 from "../img/Jones/icons/icon_5.svg";

import LogoNike from "../img/Jones/company/elevenstart.png";
import LogoAws from "../img/Jones/company/Instant.png";
import LogoIntel from "../img/Jones/company/meta.png";
import LogoSocial from "../img/Jones/company/socialseed.png";
import SocialSeeds from "../img/SocialSeeds.jpeg";
import HighLifeImg from "../img/HighLife.jpeg";
import khaleejTimes from "../img/khaleejTimes.jpeg";
import LogoTimes from "../img/Jones/logo_times.svg";

import LogoYahoo from "../img/Jones/yahoo1.png";
import LogoThrive from "../img/Jones/thrive1.png";
import LogoUnited from "../img/Jones/liverpool1.png";
import LogoManchester from "../img/Jones/manchester.png";
import LogoForbs from "../img/Jones/forbes1.png";
import forbesBgBlack from "../img/Jones/forbesBgBlack.png";

import LogoBbc from "../img/Jones/logo_bbc.svg";
import LogoUs from "../img/Jones/logo_ustoday.svg";
import LogoEnter from "../img/Jones/logo_enter.svg";
import LogoCnn from "../img/Jones/logo_cnn.svg";
import ImgBlog1 from "../img/Jones/BlogHome1.jpg";
import ImgBlog2 from "../img/Jones/BlogHome2.jpg";
import ImgBlog3 from "../img/Jones/BlogHome3.jpg";
import ImgBlog4 from "../img/Jones/BlogHome4.png";
import TextTransition, { presets } from "react-text-transition";
const Home = () => {
  const website = ["Nftislands.com", "Instantmarketing.co", "webfi.com"];
  const navigate = useNavigate();
  const [index, setIndex] = React.useState(0);
  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  return (
    <Container fluid>
      <Row>
        <Col>
          <Container>
            <Row className="bgtopHerosection align-items-center justify-content-between">
              <Col md={3} className="text-start">
                <h1 className="sectionHeading">Jay Jones</h1>
                <h2 className="sectionMainHeading">Profession</h2>
                <p> Internet Entrepreneur</p>
                <p>
                  Specialising in Social Media marketing.
                </p>
                {/* <Button variant="primary" size="lg">Contact Me</Button>{' '} */}
              </Col>
            </Row>
            {/* <Row>
                            <Col>
                                <div className="bannerContainer position-relative">   
                                    <img src={Banner} class="img-fluid" />
                                    <p className="transition" style={{ position: "absolute",top: "50%", left: "55%", fontSize: "49px"}} >
                                    <TextTransition springConfig={presets.wobbly}>
                                    {website[index % website.length]}
                                     </TextTransition>
                                    </p>
                                    

                                    <div className="position-absolute top-0 left-0 w-100 h-100 d-none d-md-block">
                                        <Container fluid className="h-100">
                                            <Row className="align-items-center h-100">
                                                <Col md={3} className="text-start">
                                                    <h1 className="sectionHeading">Jay Jones</h1>
                                                    <h2 className="sectionMainHeading">Profession</h2>
                                                    <p> Internet Entrepreneur</p>
                                                    <p>Specialising in Social Media marketing, SEO and Web3 P2E gaming development.</p>
                                                    <Button variant="primary" size="lg">Contact Me</Button>{' '}
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                            </Col>
                        </Row> */}
          </Container>
        </Col>
      </Row>

      <Row>
        <Col className="section-2">
          <Container fluid className="p-0 ">
            <Row>
              <Col md={6} className="p-0 col-md-6">
                <img src={JayJones} className="img-fluid" />
              </Col>

              <Col md={4}>
                <Container fluid className="h-100">
                  <Row className="align-items-center h-100">
                    <Col className="text-start">
                      <h2 className="sectionHeading">
                        Over the past four years I have been heavily involved
                        within the online marketing sector.
                      </h2>
                      <br />
                      <p>
                        {" "}
                        I specialise in celebrity talent bookings, public relations, ecommerce and overall
social media growth.
                      </p>
                     
                      <Button
                        onClick={() => navigate("about-me")}
                        variant="primary"
                        size="lg"
                      >
                        Read More
                      </Button>{" "}
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <Row>
        <Col className="section-3 pb-5 pt-5">
          <Container>
            <Row>
              <Col>
                <h1 className="sectionMainHeading mt-5">What I Offer</h1>
              </Col>
            </Row>
            <Row className="pb-5">
              <Col md={4} className="mb-4">
                <div class="d-flex align-items-center h-100 mb-5">
                  <span className="w-25 text-start">
                    <img src={Icon1} className="img-fluid" />
                  </span>
                  <span className="w-75 fs-20 ms-3 text-start">
                    Social Media Growth
                  </span>
                </div>
              </Col>
              <Col md={4} className="mb-4">
                <div class="d-flex align-items-center h-100 mb-5">
                  <span className="w-25 text-start">
                    <img src={Icon2} className="img-fluid" />
                  </span>
                  <span className="w-75 fs-20 ms-3 text-start">
                    Press Release
                  </span>
                </div>
              </Col>
              <Col md={4} className="mb-4">
                <div class="d-flex align-items-center h-100 mb-5">
                  <span className="w-25 text-start">
                    <img src={Icon3} className="img-fluid" />
                  </span>
                  <span className="w-75 fs-20 ms-3 text-start">
                    Brand Strategy Consulting
                  </span>
                </div>
              </Col>
              <Col md={4} className="mb-4">
                <div class="d-flex align-items-center h-100 mb-5">
                  <span className="w-25 text-start">
                    <img src={Icon4} className="img-fluid" />
                  </span>
                  <span className="w-75 fs-20 ms-3 text-start">
                    Talent Booking
                  </span>
                </div>
              </Col>
              {/* <Col md={4} className="mb-4">
                <div class="d-flex align-items-center h-100 mb-5">
                  <span className="w-25 text-start">
                    <img src={Icon5} className="img-fluid" />
                  </span>
                  <span className="w-75 fs-20 ms-3 text-start">
                    Content Creation
                  </span>
                </div>
              </Col> */}
              <Col md={4} className="mb-4">
                <div class="d-flex align-items-center h-100 mb-5">
                  <span className="w-25 text-start">
                    <img src={Icon6} className="img-fluid" />
                  </span>
                  <span className="w-75 fs-20 ms-3 text-start">
                    Start Up Investment
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <Row>
        <Col>
          <Container className="pt-5 pb-5">
            <Row>
              <Col sm={12} lg={12}>
                <Container className="h-100">
                  <Row className="align-items-center justify-content-center h-100">
                    <Col className="">
                      <h2 className="sectionMainHeading">
                        Companies Associated With
                      </h2>
                    </Col>
                  </Row>
                  <br />
                  <br />
                </Container>
              </Col>
              <Col sm={12} lg={12}>
                <Container>
                  <Row>
                    {/* <Col sm={12} md={3}>
                                            <img src={LogoNike} className="mb-5 img-fluid" />
                                        </Col> */}
                    <Col sm={12} md={4}>
                      <img
                        src={LogoAws}
                        className="mb-5 img-fluid"
                        style={{ height: "200px" }}
                      />
                    </Col>

                    <Col sm={12} md={4}>
                      <img
                        src={SocialSeeds}
                        className="mb-5 img-fluid"
                        style={{ height: "200px", width: "200px" }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <img src={HighLifeImg} style={{height:"200px", width: "200px" }} className="mb-5 img-fluid" />
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <Row className="">
        <Col className="section-4 pb-5 pt-5 align-itens-center d-flex">
          <Container>
            <Row className="align-items-center justify-content-center h-100">
              <Col md={12}>
                <h2 className="sectionMainHeading mt-5 mb-5">Featured In</h2>
                <Container fluid>
                  <Row>
                    <Col
                      md={4}
                      className="text-nowrap text-center"
                      target="_blank"
                    >
                      <img src={khaleejTimes} style={{ height: "150px", borderRadius:"50%" }} />
                    </Col>
                    <Col
                      md={4}
                      className="text-nowrap text-center"
                      target="_blank"
                    >
                      <img
                        src={LogoThrive}
                        style={{ height: "150px" }}
                        className="thrive"
                      />
                    </Col>

                    <Col
                      md={4}
                      className="text-nowrap text-center"
                      target="_blank"
                    >
                      <img
                        src={forbesBgBlack}
                        style={{ height: "150px", borderRadius:"50%" }}
                        className="forbes"
                      />
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <Row>
        <Col>
          <Container className="pt-5 pb-5">
            <Row>
              <Col md={3}>
                <h1 className="sectionMainHeading mb-5">My Blog</h1>
                <Link to="/blog">
                  <Button variant="primary" size="lg">
                    Show More
                  </Button>
                </Link>{" "}
              </Col>
              <Col md={9}>
                <Container fluid>
                  <Row>
                    <Col md={6} className="d-flex align-items-center mb-5">
                      <Link to="/blog" style={{ color: "black" }}>
                        <div className="bgThemeColor rounded-3 lh-0">
                          <img src={ImgBlog1} className="img-fluid" />
                          <span className="d-inline-block p-4 fs-16 ms-3 text-start lh-20">
                            Web 3.0 Shaping the
                            <br /> Future of Finance <br />
                            consectetur adipiscing elit.
                          </span>
                        </div>
                      </Link>
                    </Col>

                    <Col md={6} className="d-flex align-items-center mb-5">
                      <Link to="/blog" style={{ color: "black" }}>
                        <div className="bgThemeColor rounded-3 lh-0">
                          <img src={ImgBlog2} className="img-fluid" />
                          <span className="d-inline-block p-4 fs-16 ms-3 text-start lh-20">
                            What is a Crypto Wallet?
                          </span>
                        </div>
                      </Link>
                    </Col>

                    <Col md={6} className="d-flex align-items-center mb-5">
                      <Link to="/blog" style={{ color: "black" }}>
                        <div className="bgThemeColor rounded-3 lh-0">
                          <img src={ImgBlog3} className="img-fluid" />
                          <span className="d-inline-block p-4 fs-16 ms-3 text-start lh-20">
                            How to Secure Your
                            <br />
                            Cryptocurrency from Theft?
                          </span>
                        </div>
                      </Link>
                    </Col>

                    <Col
                      md={6}
                      className="d-flex align-items-center mb-5 text-start"
                    >
                      <Link to="/blog" style={{ color: "black" }}>
                        <div className="bgThemeColor rounded-3 lh-0">
                          <img src={ImgBlog4} className="img-fluid" />
                          <span className="d-inline-block p-4 fs-16 ms-3 text-start lh-20">
                            Can Cryptocurrency
                            <br /> Survive Recession?
                          </span>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <Row className="">
        <Col className="section-5 pb-5 pt-5 align-itens-center d-flex">
          <Container>
            <Row className="align-items-center justify-content-center h-100">
              <Col md={6}>
                <h2 className="sectionMainHeading mb-5">Contact Me</h2>
                <Form>
                  <Container>
                    <Row>
                      <Col md={6} className="mb-5">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            size="lg"
                            type="email"
                            placeholder="name@example.com"
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6} className="mb-5">
                        <Form.Group
                          className="mb-3 rounded-pill border-primary"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            size="lg"
                            type="email"
                            placeholder="name@example.com"
                          />
                        </Form.Group>
                      </Col>

                      <Col sm={12} className="mb-5">
                        <Form.Group
                          className="mb-3 rounded-pill border-primary"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Control as="textarea" rows={3} />
                        </Form.Group>
                      </Col>

                      <Col sm={12} className="mb-5">
                        <Button
                          variant="outline-primary"
                          className="w-50 rounded-pill bg-white text-dark"
                          size="lg"
                        >
                          Send Message
                        </Button>{" "}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <FooterComponent />
    </Container>
  );
};

export default Home;
